exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-menu-mg-js": () => import("./../../../src/pages/menu-mg.js" /* webpackChunkName: "component---src-pages-menu-mg-js" */),
  "component---src-pages-menu-update-js": () => import("./../../../src/pages/menu-update.js" /* webpackChunkName: "component---src-pages-menu-update-js" */),
  "component---src-pages-menu-update-mississauga-js": () => import("./../../../src/pages/menu-update-mississauga.js" /* webpackChunkName: "component---src-pages-menu-update-mississauga-js" */)
}

